import React, { useEffect, useState } from "react";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonPage,
  IonRow,
  useIonToast
} from "@ionic/react";
import { useParams } from "react-router-dom";
import { resetPassword } from "../../../services/userService";
import { useHistory } from "react-router-dom";

interface RouteParams {
  token: string;
}

const ResetPassword: React.FC = () => {
  const { token } = useParams<RouteParams>();
  const [loading, setLoading] = useState(true);
  const [reset, setReset] = useState(false);
  const [present] = useIonToast();
  let history = useHistory();

  const presentToast = (position: 'top' | 'middle' | 'bottom', color: 'success' | 'warning' | 'danger', message: string) => {
    present({
      message: message,
      duration: 1500,
      position: position,
      color: color
    });
  };

  const handleResetPassword = async (token: string) => {
    try {
      const resetPasswordResponse = await resetPassword(token);
      presentToast('bottom', 'success', resetPasswordResponse.message);
      setReset(true);
    } catch (error: any) {
      presentToast('bottom', 'danger', error.message);
    } finally {
      setLoading(false); // Ensure loading is set to false after the operation completes
    }
  };

  useEffect(() => {
    handleResetPassword(token);
  }, [token]);

  useEffect(() => {
    if(reset){     
        history.push("/v1");
    }
  }, [reset]);

  if (loading) {
    return (
      <IonPage>
        <IonContent fullscreen={true}>
          <IonGrid className="ion-padding" fixed={true}>
            <IonRow className="ion-justify-content-center">
              <IonCol style={{ border: "0px" }}>
                <div className="ion-text-center">
                  <IonIcon />
                  <h5>Procesando...</h5>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  }

  return (
    <IonPage>
      <IonContent fullscreen={true}>
        <IonGrid className="ion-padding" fixed={true}>
          <IonRow className="ion-justify-content-center">
            <IonCol style={{ border: "0px" }}>
              <div className="ion-text-center">
                <h5>Bienvenido</h5>
              </div>
            </IonCol>
          </IonRow>
          <IonRow className="ion-margin-top ion-padding-top ion-justify-content-center">
            {/* Other content */}
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
