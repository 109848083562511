import React, { useEffect, useState } from "react";
import { useIonViewDidEnter } from '@ionic/react';
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchMaterials, selectMaterialLists } from "./materialSlice";
import { IMaterial } from "./interface";
import { MaterialsFormPage } from "./Form";
import { useRef } from 'react';
import {
  IonButton,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonSkeletonText,
  IonThumbnail,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonAvatar,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonImg,
  IonIcon,
  IonNav
} from "@ionic/react";
import { ImageComponent } from "../../components/ImageComponent";

import {
  create as createIcon
} from "ionicons/icons";


export const MaterialsListPage = () => {
  const dispatch = useAppDispatch();

  const materialsList = useAppSelector(selectMaterialLists);
  const [loading, setLoading] = useState(true);

  /*useEffect(() => {
    dispatch(fetchMaterials());
  }, [dispatch]);*/

  useIonViewDidEnter(() => {
    dispatch(fetchMaterials());
  });

  const handleImageLoad = () => {
    // Image finished loading
    setLoading(false);
  };
  const nav = useRef<HTMLIonNavElement>(null);
  const goToPageTwo = () => {
    if (nav.current) {
      nav.current.push(MaterialsFormPage); // Push to PageTwo when the button is clicked
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle size="large">Materiales</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <>
          {!materialsList.isLoadingMaterials && materialsList.materialList && (
            <IonList>
              <IonListHeader>Lista de materiales</IonListHeader>
              {materialsList.materialList.map((record: IMaterial, index) => (
                <>
                  <IonCard>
                    <ImageComponent />
                    <IonCardHeader>
                      <IonCardTitle>{index} {record.nombre[0]}</IonCardTitle>
                      <IonCardSubtitle>{record.linea}</IonCardSubtitle>
                    </IonCardHeader>

                    <IonCardContent>
                      {/*<pre>{JSON.stringify(record, null, 2)}</pre>*/}
                      {record.unidadmedidaentrada}
                    </IonCardContent>
                    <IonToolbar>
                      <IonButtons slot="end">
                        <IonButton color="danger" size="default" fill="solid" expand="full" shape="round">
                          Eliminar
                          <IonIcon slot="start" icon={createIcon}></IonIcon>
                        </IonButton>
                        <IonButton
                          color="primary"
                          size="default"
                          fill="solid"
                          expand="full"
                          shape="round"
                          routerLink={`/page/materials/${record.id}/edit`}>
                          Editar
                          <IonIcon slot="end" icon={createIcon}></IonIcon>
                        </IonButton>
                      </IonButtons>
                    </IonToolbar>

                  </IonCard>
                </>
              ))}
            </IonList>
          )}
          {materialsList.isLoadingMaterials && (
            <IonList>
              <IonListHeader>
                <IonSkeletonText
                  animated={true}
                  style={{ width: "80px" }}
                ></IonSkeletonText>
              </IonListHeader>
              <IonItem>
                <IonThumbnail slot="start">
                  <IonSkeletonText animated={true}></IonSkeletonText>
                </IonThumbnail>
                <IonLabel>
                  <h3>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "80%" }}
                    ></IonSkeletonText>
                  </h3>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "60%" }}
                    ></IonSkeletonText>
                  </p>
                  <p>
                    <IonSkeletonText
                      animated={true}
                      style={{ width: "30%" }}
                    ></IonSkeletonText>
                  </p>
                </IonLabel>
              </IonItem>
            </IonList>
          )}
        </>
      </IonContent>
    </IonPage>
  );
};
