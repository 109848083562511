import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRow,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

import React from "react";
import { useLocation } from "react-router-dom";
import {
  cashOutline,
  cashSharp,
  cardSharp,
  businessOutline,
  businessSharp,
  ticketOutline,
  ticketSharp,
  personRemoveOutline,
  personRemoveSharp,
  settingsOutline,
  settingsSharp,
  logOutOutline,
  logOutSharp,
  albums,
  people,
  peopleSharp,
  homeOutline,
  homeSharp,
  leaf,
  leafOutline,
  leafSharp,
  albumsSharp,
  albumsOutline
} from "ionicons/icons";
import "./Menu.css";

import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { authenticateUser, selectAuthentication } from '../pages/auth/login/loginSlice'
import authService from "../services/auth.service";
import { persistor } from '../store';
import { Redirect, useHistory } from "react-router-dom";

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}
//const { t, i18n } = useTranslation();

interface MenuProps {
  isAuthenticated: boolean;
}

const Menu: React.FC<MenuProps> = ({ isAuthenticated }) => {
  const location = useLocation();
  const currentUser = "";
  const { t } = useTranslation();
  const { isProcessingRequest, user, error } = useSelector(selectAuthentication);
  const history = useHistory();

  const handleLogout = async () => {
    authService.logout(); // Perform logout logic
    persistor.purge().then(() => {
      window.location.reload();
    });
  };

  const appPages: AppPage[] = [
    {
      title: t("Home"),
      url: "/page/home",
      iosIcon: homeOutline,
      mdIcon: homeSharp,
    },
    /*{
      title: t("contacts"),
      url: "/page/contacts",
      iosIcon: people,
      mdIcon: peopleSharp,
    },
    {
      title: t("my-finances"),
      url: "/page/finanzas",
      iosIcon: cashOutline,
      mdIcon: cashSharp,
    },*/
    {
      title: t("Materiales"),
      url: "/page/materials",
      iosIcon: leaf,
      mdIcon: leafSharp,
    },
    {
      title: t("Clasificaciones"),
      url: "/page/materials-classification",
      iosIcon: albums,
      mdIcon: albumsSharp,
    },
    /*{
      title: t("amenities"),
      url: "/page/areascomunes",
      iosIcon: businessOutline,
      mdIcon: businessSharp,
    },*/
    /****Pendiente***{
      title: 'Encuestas',
      url: '/tabs',
      iosIcon: statsChartOutline,
      mdIcon: statsChartSharp
    },
    {
      title: 'Trasparencia',
      url: '/tabs',
      iosIcon: searchCircleOutline,
      mdIcon: searchCircleSharp
    },
    {
      title: t("tickets"),
      url: "/page/tickets",
      iosIcon: ticketOutline,
      mdIcon: ticketSharp,
    },*/
    /*{
      title: t("debtors"),
      url: "/page/morosos",
      iosIcon: personRemoveOutline,
      mdIcon: personRemoveSharp,
    },*/
    {
      title: t("settings"),
      url: "/page/configuracion",
      iosIcon: settingsOutline,
      mdIcon: settingsSharp,
    },
    /*{
      title: t("logout"),
      url: "/logout",
      iosIcon: logOutOutline,
      mdIcon: logOutSharp,
    },*/
  ];

  //const { t, i18n } = useTranslation();
  if (!user)
    return (<> </>)

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <img
            style={{ width: "150px" }}
            alt="Silhouette of a person's head"
            src="/assets/images/logo-san-gabriel-header.png"
          />
          <IonListHeader>
            <IonSelect interface="popover" placeholder={t("select")} value={"inventario"}>
              <IonSelectOption aria-selected value="inventario">
                {t("Inventario")}
              </IonSelectOption>
              <IonSelectOption value="produccion">
                {t("Producción")}
              </IonSelectOption>
              <IonSelectOption value="rh">
                {t("Recursos Humanos")}
              </IonSelectOption>
            </IonSelect>
          </IonListHeader>
          <IonRow>
            <IonNote>{currentUser}</IonNote>
          </IonRow>
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem
                  className={
                    location.pathname === appPage.url ? "selected" : ""
                  }
                  routerLink={appPage.url}
                  routerDirection="none"
                  lines="none"
                  detail={false}
                >
                  <IonIcon slot="start" icon={appPage.iosIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}

          <IonItem button detail={true}  onClick={() => handleLogout()}>
            <IonIcon slot="start" icon={logOutOutline} />
            <IonLabel>Log Out</IonLabel>
          </IonItem>
        </IonList>
        {error && <p>Error: {error}</p>}

        {/* Display user data after successful login 
        {user && (
          <div>
            <pre>{JSON.stringify(user, null, 2)}</pre>

          </div>
        )}*/}
      </IonContent>
    </IonMenu>
  );
};

export default withTranslation()(Menu);
