import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import API from "../../services/api";
import { RootState } from "../../store";

// Define the shape of your tour object
interface Tour {
  _id: string;
  created_at: string;
  deleted_at: string | null;
  name: string[];  // Array of strings
  updated_at: string;
  image: string
}

type Item = {
  _id: string;
  created_at: string;
  deleted_at: string | null;
  name: string[];  // Array of strings
  updated_at: string;
  image: string
};

type Pagination = {
  page: number;
  per_page: number;
  total_items: number;
  total_pages: number;
};

type ItemsResponse = {
  items: Item[];
  pagination: Pagination;
};

interface TourState {
  tour: Tour | null;
  tours: Tour[];
  userTours: Item[];
  tagTours: Tour[];
  relatedTours: Tour[];
  currentPage: number;
  numberOfPages: number | null;
  perPage: number | null;
  error: string | null;
  loading: boolean;
}

// Define initial state with typing
const initialState: TourState = {
  tour: null,
  tours: [],
  userTours: [],
  tagTours: [],
  relatedTours: [],
  currentPage: 1,
  numberOfPages: null,
  perPage: 50,  
  error: null,
  loading: false,
};

// Async thunk actions with proper types
export const createTour = createAsyncThunk<
  Tour, // Return type
  { updatedTourData: Tour; navigate: Function; toast: any }, // Thunk argument type
  { rejectValue: { message: string } } // Error type
>(
  "tour/createTour",
  async ({ updatedTourData, navigate, toast }, { rejectWithValue }) => {
    try {
      const response = await API.post("/tour", updatedTourData);
      toast.success("Added Successfully");
      navigate("/dashboard");
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getToursByUser2 = createAsyncThunk<
  Tour[], // Return type
  string, // Thunk argument (userId)
  { rejectValue: { message: string } } // Error type
>(
  "tour/getToursByUser",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await API.get(`/tour/userTours/${userId}`);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getToursByUser = createAsyncThunk<
ItemsResponse, // Return type
  string, // Thunk argument (userId)
  { rejectValue: { message: string } } // Error type
>(
  "tour/getToursByUser",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await API.get(`/materials-classification`);
      //return response.data.items;
      return response.data;

    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateTour = createAsyncThunk<
  Tour, // Return type
  { id: string; updatedTourData: Tour; toast: any; navigate: Function }, // Thunk argument type
  { rejectValue: { message: string } } // Error type
>(
  "tour/updateTour",
  async ({ id, updatedTourData, toast, navigate }, { rejectWithValue }) => {
    try {
      const response = await API.patch(`/tour/${id}`, updatedTourData);
      toast.success("Tour Updated Successfully");
      navigate("/dashboard");
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteTour = createAsyncThunk<
  string, // Return type (id of deleted tour)
  { id: string; toast: any }, // Thunk argument type
  { rejectValue: { message: string } } // Error type
>(
  "tour/deleteTour",
  async ({ id, toast }, { rejectWithValue }) => {
    try {
      await API.delete(`/tour/${id}`);
      toast.success("Tour Deleted Successfully");
      return id;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setTour(state, action: PayloadAction<string>) {
      // Find the tour by ID in the userTours list
      const selectedTour = state.userTours.find(
        (tour) => tour._id === action.payload
      );
      if (selectedTour) {
        state.tour = selectedTour;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTour.pending, (state) => {
        state.loading = true;
      })
      .addCase(createTour.fulfilled, (state, action) => {
        state.loading = false;
        state.tours = [action.payload];
      })
      .addCase(createTour.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Error creating tour";
      })
      .addCase(getToursByUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(getToursByUser.fulfilled, (state, action) => {
        console.log('fulfilled',state, action)
        state.loading = false;
        state.userTours = action.payload.items;
        state.currentPage = action.payload.pagination.page;
        state.numberOfPages = action.payload.pagination.total_pages;
        state.perPage = action.payload.pagination.per_page;
      })
      .addCase(getToursByUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Error fetching tours";
      })
      .addCase(updateTour.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTour.fulfilled, (state, action) => {
        state.loading = false;
        const updatedTour = action.payload;
        state.userTours = state.userTours.map((item) =>
          item._id === updatedTour._id ? updatedTour : item
        );
        state.tours = state.tours.map((item) =>
          item._id === updatedTour._id ? updatedTour : item
        );
      })
      .addCase(updateTour.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Error updating tour";
      })
      .addCase(deleteTour.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteTour.fulfilled, (state, action) => {
        state.loading = false;
        const deletedId = action.payload;
        state.userTours = state.userTours.filter((item) => item._id !== deletedId);
        state.tours = state.tours.filter((item) => item._id !== deletedId);
      })
      .addCase(deleteTour.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload?.message || "Error deleting tour";
      });
  },
});

export const { setCurrentPage } = tourSlice.actions;
export const { setTour } = tourSlice.actions;
export const selectMaterialLists = (state: RootState) => state.tour;
export default tourSlice.reducer;
