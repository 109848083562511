import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { createTour, updateTour, setTour } from "./tourSlice";

import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonBackButton,
  IonButton,
  IonLabel,
  IonList,
  IonItem,
} from '@ionic/react';

import { FormInputText } from "../../components/form/FormInputText";

export const MaterialesClassificationFormPage = () => {
  const dispatch = useAppDispatch();
  const { materialId } = useParams<{ materialId: string }>();

  // Get tour data from Redux store
  const { tour, userTours } = useAppSelector((state: any) => ({
    ...state.tour,
  }));

  // Validation schema with Yup
  const schema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
  });

  // React Hook Form setup
  const { handleSubmit, control, reset, formState: { errors } } = useForm({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: tour, // default values set to tour data from Redux
  });

  // Handle form submission
  const onSubmit = (data: any) => {
    console.log(data); // You can submit the data to create or update the tour
  };

  // Effect to update the form when materialId or tour from Redux changes
  useEffect(() => {
    if (materialId) {
      const singleTour = userTours.find((tour: any) => tour._id === materialId);
      if (singleTour) {
        reset(singleTour); // Reset the form with selected tour data
        dispatch(setTour(materialId)); // Dispatch action to set tour in Redux
      }
    }
  }, [materialId, userTours, reset, dispatch]);

  // Handle form clearing
  const handleClear = () => {
    reset({ name: "", image: "" }); // Reset the form fields
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          <IonTitle size="large">Materiales {materialId}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <form onSubmit={handleSubmit(onSubmit)}>
            <IonItem>
              <IonLabel position="floating">Name</IonLabel>
              <FormInputText
                name="name"
                control={control}
                errors={errors}
                label="Name"
                type="text"
              />
            </IonItem>
            <IonButton type="submit">Submit</IonButton>
            <IonButton onClick={handleClear}>Clear</IonButton>
          </form>
        </IonList>
      </IonContent>
    </IonPage>
  );
};
