import React, { useState } from 'react';
import { IonImg, IonSpinner } from '@ionic/react';

export const ImageComponent: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    // Image finished loading
    setLoading(false);
  };

  return (
    <div>
      {loading && <IonSpinner />} {/* Show spinner while loading */}
      <IonImg
        src="https://www.rtv.org.mx/masnoticias/wp-content/uploads/sites/13/2021/12/limon-persa2-e1640800536610.jpg"
        onIonImgDidLoad={handleImageLoad}  // Set the handler for image load event
        style={{ maxWidth: '100%', height: '186px', width: '100%' }}
      />
    </div>
  );
};
