const env = {
  development: {
    BASE_URL: "https://alpha.gruposangabriel.com.mx/",
    USER_NAME: "tesillosguns@gmail.com",
    PASSWORD: "samplereact",
    BACKEND_URL: "https://api-dev.gruposangabriel.com.mx/"
  },
  production: {
    BASE_URL: "https://prod.gruposangabriel.com.mx/",
    USER_NAME: "tesillosguns@gmail.com",
    PASSWORD: "samplereact",
    BACKEND_URL: "https://api-dev.gruposangabriel.com.mx/"
  },
  test: {
    BASE_URL: "https://alpha.gruposangabriel.com.mx/",
    USER_NAME: "tesillosguns@gmail.com",
    PASSWORD: "samplereact",
    BACKEND_URL: "https://api-dev.gruposangabriel.com.mx/"
  },
};

export const env_var = env[process.env.NODE_ENV];
