import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated } from "../../services/authenticationService";

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const routeComponent = (props: any) =>
    isAuthenticated() ? (
      <Component {...props} /> // Pass props including match, history, location, etc.
    ) : (
      <Redirect to={{ pathname: "/login" }} />
    );
  return <Route {...rest} render={routeComponent} />;
};
