// src/form-component/FormInputText.tsx
import React, { useState } from 'react';
import { Controller } from "react-hook-form";
import {
    IonInput,
  } from '@ionic/react';
import { FormInputProps } from "./FormInputProps";
export const FormInputText = ({ name, control, label, type }: FormInputProps) => {
    const [isTouched, setIsTouched] = useState(false);
    const markTouched = () => {
        setIsTouched(true);
      };
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange, value },
        fieldState: { error },
        formState,
      }) => (
        <IonInput
          className={`${!error && 'ion-valid'} ${error && 'ion-invalid'} ${isTouched && 'ion-touched'}`}
          onIonChange={onChange}
          value={value}
          errorText={error ? error.message : ''}
          type={type}
          onIonBlur={() => markTouched()}
        />
      )}
    />
  );
};