import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getMaterialById, fetchMaterials, selectMaterialLists } from "./materialSlice";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonBackButton,
} from "@ionic/react";

export const MaterialsFormPage = () => {
  const dispatch = useAppDispatch();
  const { materialId } = useParams<{ materialId: string }>();
  const materialsList = useAppSelector(selectMaterialLists);
  //dispatch(getMaterialById(materialId))
  const selectedMaterial = materialsList.materialList?.find(material => material.id === materialId);
  
  //const material = useAppSelector(selectMaterial);
  //console.log('materialId', material)
  const [loading, setLoading] = useState(true);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
        <IonButtons slot="start">
            <IonMenuButton />
            <IonBackButton />
          </IonButtons>
          <IonTitle size="large">Materiales {materialId}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
      <pre>{JSON.stringify(selectedMaterial, null, 2)}</pre>
      </IonContent>
    </IonPage>
  );
};
