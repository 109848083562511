import { useAppDispatch } from "../../hooks";
import { getToursByUser } from "./tourSlice";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonMenuButton,
  IonIcon,
} from "@ionic/react";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonItem,
  IonLabel,
  IonList,
  IonThumbnail,
} from '@ionic/react';
import { IonBackButton, IonButton } from '@ionic/react';
import { create as createIcon, trash as trashIcon } from 'ionicons/icons';
import { addCircle } from 'ionicons/icons';

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useIonViewDidEnter } from '@ionic/react';
import ImageWithFallback from "../../components/ImageWithFallback";


export const MaterialesClassificationListPage = () => {
  const { userTours, loading } = useSelector((state: any) => ({ ...state.tour }));
  const dispatch = useAppDispatch();
  const userId = '1';

  useEffect(() => {
    console.log('Dispatching getToursByUser for userId:', userId);
    dispatch(getToursByUser(userId));
  }, [dispatch]);

  useIonViewDidEnter(() => {
    dispatch(getToursByUser(userId));
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle size="large">Clasificacion de Materiales</IonTitle>
          <IonButtons slot="primary">
            <IonButton shape="round" fill="solid">
              Add
              <IonIcon slot="icon-only" icon={addCircle}></IonIcon>
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <>
          {loading && <div>Loading...</div>}
          {userTours && userTours.map((item: any, index: number) => (
            <>
              <IonCard key={index}>
                <IonCardContent>
                  <IonList>
                    <IonItem>
                      <IonThumbnail slot="start">
                        <ImageWithFallback src={`${item.image}`} alt={item.title} />
                      </IonThumbnail>
                      <IonLabel>{item.name}</IonLabel>
                    </IonItem>
                  </IonList>
                </IonCardContent>
                <IonToolbar>
                  <IonButtons slot="end">
                    <IonButton 
                    color="primary" 
                    fill="clear"
                    routerLink={`/page/materials-classification/${item._id}/edit`}>
                      Edit
                      <IonIcon slot="end" icon={createIcon}></IonIcon>
                    </IonButton>
                    <IonButton color="danger" fill="clear">
                      Delete
                      <IonIcon slot="end" icon={trashIcon}></IonIcon>
                    </IonButton>
                  </IonButtons>
                </IonToolbar>
              </IonCard>
            </>
          ))}
          {!loading && !userTours && <div>No data available</div>}
        </>
        {/*<pre>{JSON.stringify(userTours, null, 2)}</pre>*/}
      </IonContent>
    </IonPage>
  );
};
