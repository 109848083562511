import { Redirect, Route } from "react-router-dom";
import React, { Suspense, useState, useEffect } from "react";
import {
  IonApp,
  IonRouterOutlet,
  IonSplitPane,
  setupIonicReact,
  IonSpinner,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import AppTabs from "./AppTabs";
import FinanzasTabsPage from "./pages/FinanzasTabsPage";
import AreasComunesTabsPage from "./pages/AreasComunesTabsPage";
import AreaComunPage from "./pages/areasComunes/AreaComunPage";
import MorososPage from "./pages/MorososPage";
import ConfiguracionPage from "./pages/ConfiguracionPage";

import CreateContactPage from "./pages/CreateContactPage";
import CategoriasPage from "./pages/CategoriasPage";
import BlogPage from "./pages/BlogPage";
import CreateTicketPage from "./pages/CreateTicketPage";
import SubirComprobantePage from "./pages/SubirComprobantePage";
import LogOutPage from "./pages/LogOutPage";
import HomePage from "./pages/Home";

import NotFoundPage from "./pages/NotFoundPage";
import Menu from "./components/Menu";
import AuthService from "./services/auth.service";
import { PrivateRoute } from "./components/PrivateRoute";
/* Theme variables */
import "./theme/variables.css";
import Signup from "./pages/Signup";
import Login from "./pages/auth/login/Login";
import { UsersListPage } from "./pages/Users-List";
import { MaterialsListPage } from "./pages/Materiales-List";
import { MaterialsFormPage } from "./pages/Materiales-List/Form";
import { MaterialesClassificationListPage } from "./pages/MaterialesClassificationList";
import { MaterialesClassificationFormPage } from "./pages/MaterialesClassificationList/Form";

import ResetPassword from './pages/auth/reset-password/ResetPassword';

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/**
 * Ionic Dark Palette
 * -----------------------------------------------------
 * For more information, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

// import '@ionic/react/css/palettes/dark.always.css';
import "@ionic/react/css/palettes/dark.class.css";
// import '@ionic/react/css/palettes/dark.system.css';

/* Theme variables */
import "./theme/variables.css";
import { withTranslation } from "react-i18next";
import ContactPage from "./pages/ContactPage";
import ProductPage from "./pages/ProductPage";
import CreateProductPage from "./pages/CreateProductPage";

import { isAuthenticated } from "./services/authenticationService";

setupIonicReact();
export interface TodoInterface {
  id: string;
  task: string;
  completed: boolean;
}
const App: React.FC = () => {
  const [isAuth, setIsAuthenticated] = useState(false);
  if (true) {
    document.body.classList.remove("dark");
  } else {
    document.body.classList.remove("dark");
  }

  useEffect(() => {
    // Simulate login check (replace with real logic)
    const checkAuthStatus = () => {
      // Example: Check if user is logged in from auth provider/local storage
      const authStatus = isAuthenticated();
      setIsAuthenticated(authStatus);
    };
    
    checkAuthStatus();
  }, []);

  return (
    <Suspense
      fallback={
        <div className="spin">
          <IonSpinner></IonSpinner>
        </div>
      }
    >
      <IonApp>
        <>
            <IonReactRouter>
              <IonSplitPane contentId="main">
                <Menu isAuthenticated={isAuth}/>
                <IonRouterOutlet id="main">
                  <PrivateRoute exact path="/">
                    <Redirect to={{ pathname: "/page/home" }} />
                  </PrivateRoute>
                  <PrivateRoute path="/v1" component={UsersListPage} />
                  <PrivateRoute exact path="/page/materials" component={MaterialsListPage} />
                  <PrivateRoute exact path="/page/materials/:materialId/edit" component={MaterialsFormPage} />
                  <PrivateRoute exact path="/page/materials-classification" component={MaterialesClassificationListPage} />
                  <PrivateRoute exact path="/page/materials-classification/:materialId/edit" component={MaterialesClassificationFormPage} />


                  <Route exact path="/page/home">
                    <HomePage />
                  </Route>

                  <Route exact path="/page/contacts">
                    <ContactPage pageName="Categorias" />
                  </Route>

                  <Route exact path="/page/contacts/new">
                    <CreateContactPage pageName="Contactos" />
                  </Route>

                  <PrivateRoute exact path="/page/inventory" component={ProductPage}>
                  </PrivateRoute>

                  <Route exact path="/page/products/new">
                    <CreateProductPage pageName="Products" />
                  </Route>

                  <Route exact path="/page/contactos/:categoriablogId">
                    <CategoriasPage pageName="Nombre de la categoria" />
                  </Route>

                  <Route exact path="/page/blog/:blogDescripcionId">
                    <BlogPage pageName="Nombre del Blog" />
                  </Route>

                  <Route exact path="/page/tickets">
                    <ContactPage pageName="Tickets & Sugerencias" />
                  </Route>

                  <Route exact path="/page/tickets/create">
                    <CreateTicketPage pageName="Nuevo Ticket o Sugerencia" />
                  </Route>

                  <Route exact path="/page/pagos/subir">
                    <SubirComprobantePage pageName="Nuevo Comprobante de Pago" />
                  </Route>

                  <Route exact path="/page/configuracion">
                    <ConfiguracionPage pageName="Configuración" />
                  </Route>

                  <Route exact path="/page/morosos">
                    <MorososPage pageName="Morosos" />
                  </Route>

                  <Route path="/home">
                    <AppTabs />
                  </Route>

                  <Route path="/page/finanzas">
                    <FinanzasTabsPage />
                  </Route>

                  <Route path="/page/areascomunes">
                    <AreasComunesTabsPage />
                  </Route>

                  <Route path="/page/areacomun/:areaComunId">
                    <AreaComunPage pageName="Área Comun" />
                  </Route>

                  <Route exact path="/page/pagos/subir">
                    <SubirComprobantePage pageName="Nuevo Comprobante de Pago" />
                  </Route>

                  <Route exact path="/">
                    <Redirect to="/page/anuncios" />
                  </Route>
                  <Route exact path="/logout">
                    <LogOutPage />
                  </Route>
                  <Route exact path="/login">
                    <Redirect to="/home" />
                  </Route>
                  <Route>
                    <NotFoundPage />
                  </Route>
                  <Route exact path="/reset-password/:token">
                    <ResetPassword />
                  </Route>
                  <Route exact path="/signup">
                    <Signup />
                  </Route>
                  <Route exact path="/login">
                    <Login />
                  </Route>
                  <PrivateRoute exact path="/">
                    <Redirect to={{ pathname: "/v1" }} />
                  </PrivateRoute>
                  <PrivateRoute path="/v1" component={UsersListPage} />
                  <Redirect from="/" to="/login" exact />
                </IonRouterOutlet>
              </IonSplitPane>
            </IonReactRouter>
        </>
      </IonApp>
    </Suspense>
  );
};

export default withTranslation()(App);
